import Throttle from 'lodash/throttle'
import Debounce from 'lodash/debounce'
import axios from '../../common/axios.js'
import _ from 'lodash'

import Motor_GoogleMaps from './motors/google_maps.js';
import Motor_InexGeneral from './motors/inex_general.js';

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import AreaTooBig from '../../common/ui/area_too_big.vue'

export default {
	mixins: [Motor_GoogleMaps, Motor_InexGeneral],
	data() {
		return {
			motors: ['google_maps', 'inex_general'],
			states: {},
			search_term: this.$store.state.filters.filters.q || '',
			open: false,
			results: {
				addresses: {
					order: 100,
					title: 'serach_results_title_addresses',
					results: []
				},
				cities: {
					order: 200,
					title: 'serach_results_title_cities',
					results: []
				},
				epci: {
					order: 300,
					title: 'serach_results_title_epci',
					results: []
				},
				departments: {
					order: 400,
					title: 'serach_results_title_departments',
					results: []
				},
				kreis: {
					order: 500,
					title: 'serach_results_title_kreis',
					results: []
				},
				landers: {
					order: 600,
					title: 'serach_results_title_landers',
					results: []
				},
				provincias: {
					order: 700,
					title: 'serach_results_title_provincias',
					results: []
				},
				regions: {
					order: 800,
					title: 'serach_results_title_regions',
					results: []
				},
				states: {
					order: 900,
					title: 'serach_results_title_states',
					results: []
				},
				countries: {
					order: 1000,
					title: 'serach_results_title_countries',
					results: []
				},
				// companies: {
				// 	order: 600,
				// 	title: 'serach_results_title_companies',
				// 	results: []
				// },
				favoris: {
					order: 100000,
					title: 'serach_results_title_favoris',
					results: []
				}
			},
			zoneEmptyFilter: {
				map_filter_mode :  	'rayon',
				position: 			{},
				position_name: 		'',
				range: 				this.$store.state.ui.sourcing.zone_rayon_max ? this.$store.state.ui.sourcing.zone_rayon_max/4 : 25,
				zones_filter: 		{
					layer: null,
					zones: []
				},
				area_filter: 		true,
				path: 				null
			},
			range_tool: false,
			range_temp: this.$store.state.ui.sourcing.zone_rayon_max ? this.$store.state.ui.sourcing.zone_rayon_max/4 : 25,
			hover: null
		}
	},
	beforeDestroy: function() {
		document.querySelector(".c-results-page").removeEventListener('click', this.deactivate);
	},
	created(){
		let self = this
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector(".c-results-page").addEventListener('click', this.deactivate);
		this.motors.forEach((motor) => {
			self.states[motor] = 'standby'
		})
	},
	watch: {
		search_term: Debounce(function(after, before){
			this.launchSearch()
			this.range_tool = false
			if(after != this.$store.state.filters.filters.q) this.$store.dispatch('setZoneFilters', {q: after});
		}, 500),
		'$store.state.filters.filters.q': function(after, before){
			if(after != this.search_term){
				this.search_term = after
			}
		},
		'$store.state.filters.filters.range': function(after, before){
			this.range_temp = after
		},
		range_temp: Debounce(function(after, before){
			if(after != this.$store.state.filters.filters.range){
				this.$store.dispatch('setZoneFilters', {range: after, area_filter: true}).then(() => {
					setTimeout(() => {
						this.$store.commit('UI_UPDATE_ZOOMAUTO')
					}, 50)
				})
			}
		}, 500),
		hover: Debounce(function(after, before){
			if(after){

				if(after.geometry){

					console.log('hover', after)

					let geojson = {
						"type": "FeatureCollection",
						"features": [
							{
								"type": "Feature",
								"geometry": after.geometry
							}
						]
					}
					if(after.geometry.properties) geojson.properties = after.geometry.properties
					this.$store.commit('SEARCHES_PREVIEW', geojson);
				}

			}else{
				this.$store.commit('SEARCHES_PREVIEW', null);
				if(this.search_term) this.$store.commit('UI_UPDATE_ZOOMAUTO'); //AS--> On demande un zoomauto sur la carte que s'il y a encore une recherche = on n'a pas choisi un résultat
			}
		}, 250),
	},
	methods: {
		launchSearch() {
			if(this.search_term && this.search_term.length > 2){
				this.emptyResults()
				this.motors.forEach((motor) => {
					this['getSearch_'+motor]()
				})
			}else{
				this.emptyResults()
			}
		},

		deactivate(event){
			if (!event || event.target.closest('#barre_recherches') == null) {
				// this.emptyResults()
				this.open = false
				this.range_tool = false
			}
		},

		resetSearch() {
			this.search_term = ''
			this.emptyResults()
			this.hover = null
		},

		emptyResults(){
			let self = this
			this.stopSearches()

			let _results = _.cloneDeep(this.results)

			_.forEach(_results, (category_details, category) => {
				_results[category].results = []
			})

			self.results = _results
		},

		stopSearches(){
			this.motors.forEach((motor) => {
				if (this[motor+'_cancelSearch']) this[motor+'_cancelSearch'].cancel('Reset searches');
			})
		},

		updateState(motor, state){
			let states    = _.cloneDeep(this.states)
			states[motor] = state
			this.states   = states
		},

		addResults(motor_results){

			let self = this
			let _results = _.cloneDeep(this.results)

			_.forEach(motor_results, (category_results, category) => {

				//AS--> Si la "category" demandée n'existe pas, on passe à l'enregistrement suivant
				if(!_results[category]) return

				//AS--> On ajoute les résultats de la "category" à la liste
				category_results.forEach((result) => {
					_results[category].results.push(result)
				})

			})

			self.results = _results
		},

		chooseResult(result, category){
			if(typeof this['chooseResult_'+category] == 'function') this['chooseResult_'+category](result)
			this.resetSearch()
		},

		chooseResult_addresses(result){
			let self = this

			//AS--> Supprime les zones choisies avant
			this.$store.dispatch('clear_layers');

			let zone_filters             = _.cloneDeep(this.zoneEmptyFilter)
			zone_filters.map_filter_mode = 'rayon'
			zone_filters.area_filter     = true
			zone_filters.position_name   = result.properties.name

			this.range_temp              = this.$store.state.ui.sourcing.zone_rayon_max ? this.$store.state.ui.sourcing.zone_rayon_max/4 : 25
				
			//AS--> Si la géometrie est fournie
			if(result.geometry){
				zone_filters.position = {
					lat: result.geometry.coordinates[1],
					lng: result.geometry.coordinates[0]
				}
				this.$store.dispatch('setZoneFilters', zone_filters).then(() => {
					setTimeout(() => {
						this.$store.commit('UI_UPDATE_ZOOMAUTO')
					}, 50)
				})

			//AS--> Si on a un place_id
			}else if(result.properties.place_id){
				this.loadPlace(result, 'chooseResult')
			}

		},

		//AS--> Alias de adresse pour les villes
		chooseResult_cities(result){
			this.chooseResult_addresses(result)
		},


		chooseResult_layer(result){
			this.$store.dispatch('searches_addLayer', result);
		},

		chooseResult_epci(result){
			this.chooseResult_layer(result)
		},

		chooseResult_countries(result){
			this.chooseResult_layer(result)
		},

		chooseResult_departments(result){
			this.chooseResult_layer(result)
		},

		chooseResult_regions(result){
			this.chooseResult_layer(result)
		},

		chooseResult_kreis(result){
			this.chooseResult_layer(result)
		},

		chooseResult_landers(result){
			this.chooseResult_layer(result)
		},

		chooseResult_provincias(result){
			this.chooseResult_layer(result)
		},

		chooseResult_states(result){
			this.chooseResult_layer(result)
		},


		removeAddress(){
			this.range_tool = false
			let filters = this.zoneEmptyFilter
			filters.map_filter_mode = 'aucun'
			filters.area_filter     = false
			this.$store.dispatch('setZoneFilters', filters);
		},

		removeLayer(layer_id){
			this.$store.dispatch('searches_removeLayer', layer_id);
		},


		shortTag(text){
			let max = 27
			if(this.$store.state.searches.layers.length > 1) max = 18
			if(this.$store.state.searches.layers.length > 2) max = 8
			if(text && text.length > max) text = text.substring(0, max-3)+'...'
			return text
		},

		longTag(text){
			let max = 27
			if(this.$store.state.searches.layers.length > 1) max = 18
			if(this.$store.state.searches.layers.length > 2) max = 8
			if(text && text.length > max) return text
			return null
		},

		hoverResult(result, category){

			return

			let _result = _.cloneDeep(result)

			if(category == 'cities'){
				if(!_result.geometry) _result.geometry = {}
				if(!_result.geometry.properties) _result.geometry.properties = {}
				_result.geometry.properties.maxautozoom = 12
			}

			if(_result != this.hover){
				this.hover = _result
				if(typeof this['hoverResult_'+category] == 'function') this['hoverResult_'+category](_result)
			}

		},

		leaveResult(result, category){
			this.hover = null
		},

		hoverResult_addresses(result){
			return

			
			let position = null

			//AS--> Si la géometrie est fournie
			if(result.geometry?.coordinates){
				position = {
					lat: result.geometry.coordinates[1],
					lng: result.geometry.coordinates[0]
				}

			//AS--> Si on a un place_id
			}else if(result.properties.place_id){
				this.loadPlace(result, 'hoverResult')
			}

		},

		//AS--> Alias de adresse pour les villes
		hoverResult_cities(result){
			this.hoverResult_addresses(result)
		},

		loadPlace: _.debounce( function(result, action){
			let self = this

			axios.get('/plugin_projet/plugin_projet_iannuaire/get_place_id/'+result.properties.place_id)
		  	.then(function (response) {

		  		let new_result = _.cloneDeep(result)

		  		new_result.geometry = {
		  			type: 'Point',
		  			coordinates: [
		  				response.data.position.lng, 
		  				response.data.position.lat
		  			]
		  		}

		  		//AS--> Replace le résultat dans la liste
		  		let results = _.cloneDeep(self.results)
		  		let category = null
		  		results.addresses.results.forEach((result, index) => {
		  			if(result.properties.place_id == new_result.properties.place_id){
		  				results.addresses.results[index] = new_result
		  				category = 'addresses'
		  			}
		  		})
		  		results.cities.results.forEach((result, index) => {
		  			if(result.properties.place_id == new_result.properties.place_id){

	  					if(!new_result.geometry.properties) new_result.geometry.properties = {}
	  					new_result.geometry.properties.maxautozoom = 12

		  				results.cities.results[index] = new_result
		  				category = 'cities'
		  			}
		  		})
		  		// self.results = results

		  		//AS--> Met à jour le preview si c'est le résultat en attente
		  		if(self.hover?.properties?.place_id == new_result.properties.place_id){
		  			self.hover = new_result
		  		}

		  		//AS--> Relance l'action avec les infos complètes
		  		if(action == 'chooseResult'){
		  			if(!category) category = 'addresses'
			  		self.chooseResult(new_result, category)
			  	}

			}).catch(function (error) {
				console.log('error', error)
			})	
		}, 250)

	},
	computed:{
		resutsToShow(){
			let _results = _.cloneDeep(this.results)
			let results  = []

			_.forEach(_results, (category_details, category) => {
				if(category_details.results.length){
					category_details.category = category
					results.push(category_details)
				}
			})

			return _.sortBy(results, ['order'])
		},
		sliderMarks(){
			let marks = {1: '1 km'}
			marks[this.$store.state.ui.sourcing.zone_rayon_max] = this.$store.state.ui.sourcing.zone_rayon_max+' km'
			return marks
		},

		getTags(){
			let tags = []
			if(this.$store.state.searches.layers.length){
				
				//AS--> On ajoute tous les layers aux tags
				this.$store.state.searches.layers.forEach((layer) => {
					tags.push({
						text_long: this.longTag(layer.properties.name),
						text_short: this.shortTag(layer.properties.name),
						value: layer.properties.id
					})
				})

				//AS--> S'il y a plus de 3 tags, on mémorise combien il y a de tag au total et on ne garde que les 2 derniers
				if(tags.length > 3){

					//AS--> Mémorise le nombre de tags supplémentaires
					let plus = tags.length-2

					//AS--> Ne garde que les 2 derniers tags
					tags = tags.slice(tags.length-2, tags.length)

					//AS--> Ajoute un tag au début avec le nombre de tags en plus
					tags.unshift({
						text_short: plus+' plus'
					})
				}
			}
			return tags
		},

		showWarningMessage(){
			if(this.$store.getters.areaTooBig && !this.resutsToShow.length && (this.$store.state.filters.filters.map_filter_mode != 'aucun' || this.$store.state.ui.clickClusterMarkerLast) ){
				return true
			}
			return false
		}
	},
	components:{
		VueSlider,
		AreaTooBig
	}
}